import React from "react";
import { Box, Title, Text, Stack } from "@mantine/core";
import MiniGameRow from "./MiniGameRow";
import { MiniGame } from "../dto/user";

interface BookStructureProps {
  structure: {
    title: string;
    parts: {
      title: string;
      chapters: {
        number: number;
        principle: string;
        description?: string;
        miniGameIds: number[];
      }[];
    }[];
  };
  miniGames: MiniGame[];
  onPlayGame: (miniGameId: number) => void;
}

const BookStructure: React.FC<BookStructureProps> = ({
  structure,
  miniGames,
  onPlayGame,
}) => {
  return (
    <Stack gap="xl" m="md">
      {structure.parts.map((part, partIndex) => (
        <Box key={partIndex}>
          <Title order={2} mb="lg">
            {part.title}
          </Title>
          <Stack>
            {part.chapters.map((chapter) => (
              <Box key={chapter.number} mb="md">
                <Title order={3} size="h4">
                  Principe {chapter.number} : {chapter.principle}
                </Title>
                {chapter.description && (
                  <Text size="sm" c="dimmed" mt={4}>
                    {chapter.description}
                  </Text>
                )}
                {chapter.miniGameIds.map((gameId) => {
                  const game = miniGames.find((g) => g.id === gameId);
                  if (!game) return null;
                  return (
                    <Box mt="sm" key={gameId}>
                      <MiniGameRow miniGame={game} onPlay={onPlayGame} />
                    </Box>
                  );
                })}
              </Box>
            ))}
          </Stack>
        </Box>
      ))}
    </Stack>
  );
};

export default BookStructure;
