import {
  Group,
  Image,
  Paper,
  Progress,
  Stack,
  Text,
  Button,
  Box,
  Collapse,
  useMantineColorScheme,
  MantineColorScheme,
} from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../useApi";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// import { MiniGame } from "../dto/user";
import {
  IconChevronDown,
  IconChevronUp,
  IconPlayerPlay,
} from "@tabler/icons-react";
// import MiniGameRow from "./MiniGameRow";
import BookStructure from "./BookStructure";
import howToWinFriends from "./books/howToWinFriends.json";

const BookCover = ({ colorScheme }: { colorScheme: MantineColorScheme }) => (
  <Box
    style={{
      position: "relative",
      borderRadius: "var(--mantine-radius-md)",
      overflow: "hidden",
      boxShadow:
        colorScheme === "light"
          ? "0 10px 20px rgba(0, 0, 0, 0.1)"
          : "0 10px 20px rgba(0, 0, 0, 0.3)",
    }}
  >
    <Image
      src="https://des-livres-pour-changer-de-vie.com/wp-content/uploads/2019/09/livre-comment-se-faire-des-amis.jpg"
      alt="Couverture du livre Comment se faire des amis"
      w={120}
      radius="md"
    />
  </Box>
);

const BookTitle = ({ colorScheme }: { colorScheme: MantineColorScheme }) => (
  <Stack gap={2}>
    <Text
      size="lg"
      fw={600}
      style={{
        color:
          colorScheme === "light"
            ? "var(--mantine-color-dark-7)"
            : "var(--mantine-color-gray-1)",
      }}
    >
      Deviens expert en relations humaines
    </Text>
    <Text size="sm" c={colorScheme === "light" ? "gray.7" : "gray.5"}>
      Basé sur le best-seller international de Dale Carnegie
    </Text>
  </Stack>
);

const ProgressPercentage = ({
  percentage,
  colorScheme,
}: {
  percentage: number;
  colorScheme: MantineColorScheme;
}) => (
  <Text
    size="xl"
    fw={700}
    style={{
      background:
        colorScheme === "light"
          ? "linear-gradient(135deg, var(--mantine-color-violet-6), var(--mantine-color-violet-8))"
          : "linear-gradient(135deg, var(--mantine-color-violet-4), var(--mantine-color-violet-6))",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      fontSize: "2.625rem",
      lineHeight: 1,
    }}
  >
    {percentage}%
  </Text>
);

const ProgressSection = ({
  completed,
  total,
  nextGameToPlay,
  showGames,
  colorScheme,
  onPlayGame,
  onToggleGames,
}: {
  completed: number;
  total: number;
  nextGameToPlay: any;
  showGames: boolean;
  colorScheme: MantineColorScheme;
  onPlayGame: (id: number) => void;
  onToggleGames: () => void;
}) => (
  <Group justify="space-between" align="center" mt={4}>
    <Group align="center" gap="xl">
      <Text
        size="sm"
        c={colorScheme === "light" ? "gray.7" : "gray.5"}
        fs="italic"
      >
        {completed} jeux complétés sur {total}
      </Text>
      {nextGameToPlay && (
        <div style={{ position: "relative" }}>
          <div
            style={{
              position: "absolute",
              left: "50%",
              top: "-40px",
              transform: "translateX(-50%)",
              animation: "bounce 2s infinite",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              zIndex: 1,
            }}
          >
            <Text
              fw={700}
              c="violet"
              style={{
                marginBottom: "4px",
                animation: "pulse 1.5s infinite",
                whiteSpace: "nowrap",
              }}
            >
              COMMENCER
            </Text>
            <div
              style={{
                width: 0,
                height: 0,
                borderLeft: "8px solid transparent",
                borderRight: "8px solid transparent",
                borderTop: "8px solid var(--mantine-color-violet-filled)",
              }}
            />
          </div>
          <Button
            variant="filled"
            color="violet"
            onClick={() => onPlayGame(nextGameToPlay.id)}
            size="sm"
            leftSection={<IconPlayerPlay size={16} />}
            className="transform hover:scale-105 transition-all"
            styles={{
              root: {
                boxShadow: "0 4px 12px rgba(124, 58, 237, 0.3)",
                transition: "all 0.2s ease",
                "&:hover": {
                  transform: "translateY(-2px)",
                  boxShadow: "0 6px 16px rgba(124, 58, 237, 0.4)",
                },
              },
            }}
          >
            {completed === 0
              ? "Commencer le premier jeu"
              : "Faire le mini jeu suivant"}
          </Button>
        </div>
      )}
    </Group>
    <Button
      variant={colorScheme === "light" ? "light" : "filled"}
      color="violet"
      onClick={onToggleGames}
      rightSection={
        showGames ? <IconChevronUp size={16} /> : <IconChevronDown size={16} />
      }
      radius="xl"
      size="sm"
      style={{
        boxShadow:
          colorScheme === "light"
            ? "0 2px 4px rgba(0, 0, 0, 0.05)"
            : "0 2px 4px rgba(0, 0, 0, 0.2)",
      }}
    >
      {showGames ? "Masquer les jeux" : "Voir les jeux"}
    </Button>
  </Group>
);

// const GamesList = ({
//   games,
//   onPlay,
// }: {
//   games: MiniGame[];
//   onPlay: (id: number) => void;
// }) => (
//   <Box
//     p="md"
//     pb={4}
//     // style={{
//     //   display: "grid",
//     //   gridTemplateColumns: "repeat(auto-fill, minmax(280px, 1fr))",
//     //   gap: "1rem",
//     // }}
//   >
//     {games.map((game: MiniGame) => (
//       <MiniGameRow key={game.id} miniGame={game} onPlay={onPlay} />
//     ))}
//   </Box>
// );

const BookProgress = () => {
  const api = useApi();
  const navigate = useNavigate();
  const [showGames, setShowGames] = useState(false);
  const { colorScheme } = useMantineColorScheme();

  const { data: miniGames, isLoading } = useQuery({
    queryKey: ["miniGames"],
    queryFn: () => api.get("/mini-games").then((res) => res.data),
  });

  const handlePlayMiniGame = (miniGameId: number) => {
    navigate(`/mini-game/${miniGameId}?origin=home`);
  };

  const calculateProgress = () => {
    if (!miniGames)
      return {
        percentage: 0,
        completed: 0,
        total: 0,
        bookGames: [],
        nextGameToPlay: null,
      };

    const bookGames = miniGames.filter(
      (game: any) => game.id >= 10 && game.id <= 13
    );

    const completedGames = bookGames.filter((game: any) => {
      const highestScore = game.results?.[0]?.score ?? 0;
      return highestScore >= 6;
    });

    const total = bookGames.length;
    const completed = completedGames.length;
    const percentage = total > 0 ? Math.round((completed / total) * 100) : 0;

    const nextGameToPlay = bookGames.find((game: any) => {
      const highestScore = game.results?.[0]?.score ?? 0;
      return highestScore < 6;
    });

    return { percentage, completed, total, bookGames, nextGameToPlay };
  };

  const { percentage, completed, total, bookGames, nextGameToPlay } =
    calculateProgress();

  if (isLoading) return null;

  const gradientStyle = {
    background:
      colorScheme === "light"
        ? "linear-gradient(135deg, var(--mantine-color-blue-0), var(--mantine-color-violet-1))"
        : "linear-gradient(135deg, var(--mantine-color-dark-6), var(--mantine-color-dark-5))",
  };

  return (
    <Paper shadow="sm" withBorder mt="xl" style={gradientStyle}>
      <Stack>
        <Group align="stretch" gap="xl" p="md">
          <BookCover colorScheme={colorScheme} />
          <Stack className="flex-1" gap="xs">
            <Group justify="space-between" align="center" gap="xl">
              <BookTitle colorScheme={colorScheme} />
              <ProgressPercentage
                percentage={percentage}
                colorScheme={colorScheme}
              />
            </Group>

            <Progress
              value={percentage}
              size="xl"
              radius="xl"
              color="violet"
              mt={8}
              style={{
                backgroundColor:
                  colorScheme === "light"
                    ? "var(--mantine-color-gray-1)"
                    : "var(--mantine-color-dark-4)",
              }}
            />

            <ProgressSection
              completed={completed}
              total={total}
              nextGameToPlay={nextGameToPlay}
              showGames={showGames}
              colorScheme={colorScheme}
              onPlayGame={handlePlayMiniGame}
              onToggleGames={() => setShowGames(!showGames)}
            />
          </Stack>
        </Group>

        <Collapse in={showGames}>
          {/* <GamesList games={bookGames} onPlay={handlePlayMiniGame} /> */}
          <BookStructure
            miniGames={bookGames}
            onPlayGame={handlePlayMiniGame}
            structure={howToWinFriends}
          />
        </Collapse>
      </Stack>
    </Paper>
  );
};

export default BookProgress;
