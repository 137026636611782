import React, { useState, useEffect, useCallback } from "react";
import { Text, Button, Paper, Textarea, LoadingOverlay } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import ReactMarkdown from "react-markdown";
import { ChatCompletionMessageParam } from "openai/resources";
import { io, Socket } from "socket.io-client";
import { baseUrl } from "../../useApi";
import { userLocalDate } from "../../utils/userLocalDate";
import GameResult from "./GameResult";

interface SingleQuestionGameProps {
  gameData: {
    id: number;
    description: string;
    timeLimit: number;
  };
  gameState: "not_started" | "started" | "finished" | "submitted";
  setGameState: React.Dispatch<
    React.SetStateAction<"not_started" | "started" | "finished" | "submitted">
  >;
  timeLeft: number | undefined;
  setTimeLeft: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const SingleQuestionGame: React.FC<SingleQuestionGameProps> = ({
  gameData,
  gameState,
  setGameState,
  timeLeft,
  setTimeLeft,
}) => {
  const [grade, setGrade] = useState<number | null>(null);
  const [feedback, setFeedback] = useState("");
  const [subject, setSubject] = useState("");
  const [isGeneratingSubject, setIsGeneratingSubject] = useState(false);
  const [answer, setAnswer] = useState("");
  const [chatHistory, setChatHistory] = useState<ChatCompletionMessageParam[]>(
    []
  );
  const [socket, setSocket] = useState<Socket | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [startTime, setStartTime] = useState<number | null>(null);

  const setupSocket = useCallback(() => {
    const token = JSON.parse(localStorage.getItem("user") || "{}").accessToken;
    const newSocket: Socket = io(baseUrl, {
      withCredentials: true,
      auth: { token },
    });

    newSocket.on("connect", () => {
      console.log("Connected to WebSocket");
    });

    newSocket.on("subjectChunk", (chunk: string) => {
      setSubject((prev) => prev + chunk);
    });

    newSocket.on("subjectComplete", () => {
      setIsGeneratingSubject(false);
    });

    newSocket.on("subjectError", (error) => {
      console.error("Error generating subject:", error);
      notifications.show({
        title: "Erreur",
        message: "Une erreur est survenue lors de la génération du sujet.",
        color: "red",
      });
      setIsGeneratingSubject(false);
    });

    newSocket.on(
      "resultSubmitted",
      (result: { grade: number; feedback: string }) => {
        setGrade(result.grade);
        setFeedback(result.feedback);
        setIsSubmitting(false);
        setGameState("submitted");
        notifications.show({
          title: "Résultat enregistré",
          message: "Votre partie a été sauvegardée avec succès.",
          color: "green",
        });
      }
    );

    newSocket.on("resultError", (error: { message: string }) => {
      setIsSubmitting(false);
      setGameState("finished");
      notifications.show({
        title: "Erreur",
        message: error.message,
        color: "red",
      });
    });

    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, [setGameState]);

  useEffect(() => {
    const cleanup = setupSocket();
    return cleanup;
  }, [setupSocket]);

  useEffect(() => {
    if (!isGeneratingSubject && subject) {
      setChatHistory((prev) => [
        ...prev,
        { role: "assistant", content: subject },
      ]);
    }
  }, [isGeneratingSubject, subject]);

  useEffect(() => {
    if (gameState === "started" && startTime === null) {
      setStartTime(Date.now());
    }
  }, [gameState, startTime]);

  useEffect(() => {
    if (gameState === "started" && timeLeft === 0) {
      setGameState("finished");
    }
  }, [timeLeft, gameState, setGameState]);

  useEffect(() => {
    if (gameState === "finished" && !isSubmitting) {
      submitGame();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameState, isSubmitting]);

  const handleStartGame = () => {
    setGameState("started");
    setTimeLeft(gameData.timeLimit);
    setIsGeneratingSubject(true);
    setSubject("");
    if (socket) {
      socket.emit("generateSubject", { miniGameId: gameData.id });
    }
  };

  const handleFinishGame = () => {
    if (gameState === "started") {
      setGameState("finished");
    }
  };

  const submitGame = useCallback(() => {
    if (isSubmitting) return;

    setIsSubmitting(true);
    if (socket) {
      const finalChatHistory = [
        ...chatHistory,
        { role: "user", content: answer },
      ];
      const timeSpent = startTime
        ? Math.min(
            Math.floor((Date.now() - startTime) / 1000),
            gameData.timeLimit
          )
        : gameData.timeLimit;
      socket.emit("submitMiniGameResult", {
        miniGameId: gameData.id,
        timeSpent: timeSpent,
        answer: answer,
        subject: subject,
        exerciseStatement: gameData.description,
        chatHistory: finalChatHistory,
        isTimeUp: timeLeft === 0,
        userLocalDate: userLocalDate(),
      });
    }
  }, [
    isSubmitting,
    socket,
    chatHistory,
    answer,
    startTime,
    gameData,
    subject,
    timeLeft,
  ]);

  if (gameState === "not_started") {
    return (
      <Button onClick={handleStartGame} mt="md">
        Commencer le jeu
      </Button>
    );
  }

  return (
    <div style={{ position: "relative" }}>
      <LoadingOverlay visible={isSubmitting} />
      {isGeneratingSubject && subject.length === 0 ? (
        <Text>Génération du sujet en cours...</Text>
      ) : (
        <Paper p="md" mb="md" withBorder>
          <ReactMarkdown>{subject}</ReactMarkdown>
        </Paper>
      )}
      {gameState === "started" && (
        <>
          <Textarea
            placeholder="Entrez votre réponse ici"
            minRows={5}
            value={answer}
            onChange={(event) => setAnswer(event.currentTarget.value)}
            disabled={isGeneratingSubject || isSubmitting}
            mb="md"
          />
          <Button
            onClick={handleFinishGame}
            disabled={isGeneratingSubject || isSubmitting}
          >
            Terminer le jeu
          </Button>
        </>
      )}
      {(gameState === "finished" || gameState === "submitted") && (
        <GameResult score={grade} feedback={feedback} userAnswer={answer} />
      )}
    </div>
  );
};

export default SingleQuestionGame;
