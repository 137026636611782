import React, { useState, useEffect, useCallback } from "react";
import {
  Text,
  Button,
  Stack,
  Paper,
  Radio,
  Group,
  LoadingOverlay,
  Box,
  Alert,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { useApi } from "../../useApi";
import ReactMarkdown from "react-markdown";
import { useQuery, useMutation } from "@tanstack/react-query";
import { IconCheck, IconX } from "@tabler/icons-react";
import { userLocalDate } from "../../utils/userLocalDate";
import GameResult from "./GameResult";

interface QuizQuestion {
  id: number;
  questionFr: string;
  explanationFr: string;
  answers: string; // This is a JSON string
}

interface ParsedAnswers {
  options: string[];
  correctAnswer: string;
}

interface QuizGameProps {
  gameData: {
    id: number;
    description: string;
    timeLimit: number;
  };
  gameState: "not_started" | "started" | "finished" | "submitted";
  setGameState: React.Dispatch<
    React.SetStateAction<"not_started" | "started" | "finished" | "submitted">
  >;
  timeLeft: number;
  setTimeLeft: React.Dispatch<React.SetStateAction<number | undefined>>;
  hasTimeLimit: boolean;
}

interface QuizResult {
  score: number;
  totalQuestions: number;
  quizAnswers: {
    questionId: number;
    userAnswer: string;
    correctAnswer: string;
    isCorrect: boolean;
  }[];
}

interface ShuffledOptionsMap {
  [key: number]: Array<{ option: string; value: string }>;
}

const QuizGame: React.FC<QuizGameProps> = ({
  gameData,
  gameState,
  setGameState,
  timeLeft,
  setTimeLeft,
  hasTimeLimit,
}) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState<
    Record<number, string>
  >({});
  const [quizResult, setQuizResult] = useState<QuizResult | null>(null);
  const [startTime, setStartTime] = useState<number | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [shuffledOptionsMap, setShuffledOptionsMap] =
    useState<ShuffledOptionsMap>({});
  const api = useApi();

  const {
    data: questions,
    isLoading,
    error,
  } = useQuery<QuizQuestion[]>({
    queryKey: ["quizQuestions", gameData.id],
    queryFn: () =>
      api.get(`/mini-games/${gameData.id}/questions`).then((res) => res.data),
  });

  const submitMutation = useMutation<
    QuizResult,
    Error,
    {
      answers: Record<number, string>;
      timeSpent: number;
      userLocalDate: { year: number; month: number; day: number };
    }
  >({
    mutationFn: (data) =>
      api
        .post(`/mini-games/${gameData.id}/submit`, data)
        .then((res) => res.data),
    onSuccess: (data) => {
      console.log("data (result quiz)", data);
      setQuizResult(data);
      setGameState("submitted");
      setIsSubmitting(false);
    },
    onError: (error) => {
      notifications.show({
        title: "Erreur",
        message: "Impossible de soumettre les réponses du quiz.",
        color: "red",
      });
      setIsSubmitting(false);
    },
  });

  useEffect(() => {
    if (error) {
      notifications.show({
        title: "Erreur",
        message: "Impossible de charger les questions du quiz.",
        color: "red",
      });
    }
  }, [error]);

  useEffect(() => {
    if (gameState === "started" && startTime === null) {
      setStartTime(Date.now());
    }
  }, [gameState, startTime]);

  useEffect(() => {
    if (gameState === "started" && hasTimeLimit && timeLeft === 0) {
      handleFinishGame();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft, gameState, hasTimeLimit]);

  // const handleStartGame = () => {
  //   if (questions && questions.length > 0) {
  //     setGameState("started");
  //     setTimeLeft(gameData.timeLimit);
  //   } else {
  //     notifications.show({
  //       title: "Erreur",
  //       message: "Impossible de démarrer le quiz. Veuillez réessayer.",
  //       color: "red",
  //     });
  //   }
  // };

  const handleAnswerSelect = (questionId: number, answer: string) => {
    setSelectedAnswers((prev) => ({ ...prev, [questionId]: answer }));
  };

  const handleNextQuestion = () => {
    if (questions && currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex((prev) => prev + 1);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex((prev) => prev - 1);
    }
  };

  const submitGame = useCallback(() => {
    if (isSubmitting) return;

    setIsSubmitting(true);
    const timeSpent = startTime
      ? Math.min(
          Math.floor((Date.now() - startTime) / 1000),
          gameData.timeLimit
        )
      : gameData.timeLimit;
    submitMutation.mutate({
      answers: selectedAnswers,
      timeSpent: timeSpent,
      userLocalDate: userLocalDate(),
    });
  }, [
    isSubmitting,
    startTime,
    gameData.timeLimit,
    selectedAnswers,
    submitMutation,
  ]);

  const handleFinishGame = useCallback(() => {
    if (questions && Object.keys(selectedAnswers).length < questions.length) {
      notifications.show({
        title: "Attention",
        message:
          "Veuillez répondre à toutes les questions avant de terminer le quiz.",
        color: "yellow",
      });
      return;
    }

    setGameState("finished");
    submitGame();
  }, [questions, selectedAnswers, setGameState, submitGame]);

  const parseAnswers = (answersJson: string): ParsedAnswers | null => {
    try {
      return JSON.parse(answersJson);
    } catch (e) {
      console.error("Error parsing answers JSON:", e);
      return null;
    }
  };

  useEffect(() => {
    if (gameState === "finished" && !isSubmitting) {
      submitGame();
    }
  }, [gameState, isSubmitting, submitGame]);

  useEffect(() => {
    if (questions) {
      const newShuffledOptionsMap: ShuffledOptionsMap = {};
      questions.forEach((question) => {
        const parsedAnswers = parseAnswers(question.answers);
        if (parsedAnswers) {
          const optionsWithValues = parsedAnswers.options.map(
            (option, index) => ({
              option,
              value: String.fromCharCode(65 + index),
            })
          );
          const shuffledOptions = [...optionsWithValues].sort(
            () => Math.random() - 0.5
          );
          newShuffledOptionsMap[question.id] = shuffledOptions;
        }
      });
      setShuffledOptionsMap(newShuffledOptionsMap);
    }
  }, [questions]);

  if (isLoading) {
    return <LoadingOverlay visible={true} />;
  }

  // if (gameState === "not_started") {
  //   return (
  //     <Button
  //       onClick={handleStartGame}
  //       mt="md"
  //       disabled={!questions || questions.length === 0}
  //     >
  //       Commencer le quiz
  //     </Button>
  //   );
  // }

  if ((gameState === "finished" || gameState === "submitted") && quizResult) {
    return (
      <Stack>
        <GameResult
          title="Quiz terminé !"
          score={quizResult.score}
          totalQuestions={quizResult.totalQuestions}
        />
        {questions &&
          questions.map((question, index) => {
            const result = quizResult.quizAnswers.find(
              (a) => a.questionId === question.id
            );
            const parsedAnswers = parseAnswers(question.answers);
            if (!result || !parsedAnswers) return null;

            return (
              <Paper key={question.id} p="md" withBorder mb="md">
                <Text fw={600} mb="xs">
                  Question {index + 1}:
                </Text>
                <ReactMarkdown>{question.questionFr}</ReactMarkdown>
                <Box mt="sm">
                  <Text>
                    Votre réponse:{" "}
                    {
                      parsedAnswers.options[
                        result.userAnswer.charCodeAt(0) - 65
                      ]
                    }
                  </Text>
                  <Text>
                    Réponse correcte:{" "}
                    {
                      parsedAnswers.options[
                        result.correctAnswer.charCodeAt(0) - 65
                      ]
                    }
                  </Text>
                </Box>
                {result.isCorrect ? (
                  <Alert
                    icon={<IconCheck size="1rem" />}
                    title="Correct !"
                    color="green"
                    mt="sm"
                  >
                    Bonne réponse !
                  </Alert>
                ) : (
                  <Alert
                    icon={<IconX size="1rem" />}
                    title="Incorrect"
                    color="red"
                    mt="sm"
                  >
                    <Text mb="xs">Explication :</Text>
                    <ReactMarkdown>{question.explanationFr}</ReactMarkdown>
                  </Alert>
                )}
              </Paper>
            );
          })}
      </Stack>
    );
  }

  if (!questions || questions.length === 0) {
    return <Text>Aucune question n'est disponible pour ce quiz.</Text>;
  }

  const currentQuestion = questions[currentQuestionIndex];

  if (!currentQuestion) {
    return <Text>Erreur: La question actuelle n'est pas disponible.</Text>;
  }

  const parsedAnswers = parseAnswers(currentQuestion.answers);

  if (!parsedAnswers) {
    return (
      <Text>Erreur: Les réponses pour cette question sont mal formatées.</Text>
    );
  }

  const isAnswerSelected = selectedAnswers[currentQuestion.id] !== undefined;
  const shuffledOptions = shuffledOptionsMap[currentQuestion.id];

  if (!shuffledOptions) {
    return (
      <Text>Erreur: Les réponses pour cette question sont mal formatées.</Text>
    );
  }

  return (
    <Stack>
      <Paper p="md" withBorder>
        <Text fw={700} mb="xs">
          Question {currentQuestionIndex + 1} / {questions.length}
        </Text>
        <ReactMarkdown>{currentQuestion.questionFr}</ReactMarkdown>
        <Radio.Group
          value={selectedAnswers[currentQuestion.id] || ""}
          onChange={(value) => handleAnswerSelect(currentQuestion.id, value)}
        >
          <Stack mt="md">
            {shuffledOptions.map(
              ({ option, value }: { option: string; value: string }) => (
                <Radio key={value} value={value} label={option} />
              )
            )}
          </Stack>
        </Radio.Group>
      </Paper>
      <Group>
        <Button
          onClick={handlePreviousQuestion}
          disabled={currentQuestionIndex === 0}
        >
          Précédent
        </Button>
        {currentQuestionIndex < questions.length - 1 ? (
          <Button onClick={handleNextQuestion} disabled={!isAnswerSelected}>
            Suivant
          </Button>
        ) : (
          <Button onClick={handleFinishGame} disabled={!isAnswerSelected}>
            Terminer le quiz
          </Button>
        )}
      </Group>
    </Stack>
  );
};

export default QuizGame;
