import React from "react";
import { Badge } from "@mantine/core";
import { IconStar } from "@tabler/icons-react";

const DisplayResult = ({
  score,
  ml,
  light,
}: {
  score: number | undefined;
  ml?: number | string;
  light?: boolean;
}) => {
  let color = "red";
  let label = "Non validé";
  let filledStars = 0;
  const totalStars = 3;

  if (!score || (score < 6 && score !== -1)) return null;

  if (score && score >= 6) {
    color = "green";
    label = "Validé";

    if (score >= 8.5) {
      filledStars = 3;
    } else if (score >= 7.5) {
      filledStars = 2;
    } else if (score >= 6.5) {
      filledStars = 1;
    }
  }

  if (score === -1) {
    color = "green";
    label = "Validé";
  }

  if (light) label = "";

  return (
    <Badge color={color} size="lg" variant="filled" ml={ml}>
      {label}{" "}
      {score !== -1 &&
        [...Array(totalStars)].map((_, i) => (
          <IconStar
            key={i}
            size={14}
            fill={i < filledStars ? "gold" : "none"}
            color="gold"
            style={{ marginBottom: -2 }}
          />
        ))}
    </Badge>
  );
};

export default DisplayResult;
