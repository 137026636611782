import { Avatar, Group, Stack, Text } from "@mantine/core";
import { Interlocutor } from "../../dto/user";

const ChatHeaderAvatar = ({
  interlocutor,
}: {
  interlocutor?: Interlocutor;
}) => {
  return (
    <Group justify="center" m="xs">
      <Avatar
        src={
          interlocutor
            ? interlocutor.avatarUrl
            : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRALVqKDzVCPZ3mfStfyt6Ijexu3BzP8KWLeA&s"
        }
      />
      <Stack gap={0}>
        <Text>{interlocutor ? interlocutor.name : "Kermit"}</Text>
        <Group gap={5}>
          <div
            style={{
              width: 8,
              height: 8,
              backgroundColor: "green",
              borderRadius: 10,
            }}
          />
          <Text size="xs" c="dimmed">
            en ligne
          </Text>
        </Group>
      </Stack>
    </Group>
  );
};
export default ChatHeaderAvatar;
