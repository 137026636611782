import React from "react";
import { Box, SimpleGrid, Card, Text, Title, Stack } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { areasOfLife } from "../utils/areasOfLife";

const Domains: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Box p={16}>
      <Title order={1} mb="lg">
        Domaines de vie
      </Title>
      <SimpleGrid cols={{ sm: 2, lg: 3, xl: 4 }} spacing="lg">
        {areasOfLife.map((area) => (
          <Card
            key={area.id}
            shadow="sm"
            padding="lg"
            radius="md"
            withBorder
            style={{
              cursor: "pointer",
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${area.backgroundImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
            onClick={() => navigate(`/areas/${area.value}`)}
          >
            <Stack gap="xs" align="center">
              <Text size="xl" fw={500} c="white" ta="center" lineClamp={2}>
                {area.emoji} {area.title}
              </Text>
              <Text size="sm" c="white" ta="center" lineClamp={3}>
                {area.description}
              </Text>
            </Stack>
          </Card>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default Domains;
