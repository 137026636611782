import { useState, useEffect, useCallback } from "react";

interface UseElapsedTimeProps {
  isRunning: boolean;
  hasTimeLimit: boolean;
}

export const useElapsedTime = ({
  isRunning,
  hasTimeLimit,
}: UseElapsedTimeProps) => {
  const [startTime, setStartTime] = useState<number | null>(null);
  const [elapsedTime, setElapsedTime] = useState(0);

  useEffect(() => {
    // Ne démarrer le compteur que s'il n'y a pas de limite de temps
    if (isRunning && !hasTimeLimit) {
      setStartTime(Date.now());
    }

    return () => {
      if (startTime && !hasTimeLimit) {
        const finalElapsedTime = Math.floor((Date.now() - startTime) / 1000);
        setElapsedTime(finalElapsedTime);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRunning, hasTimeLimit]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (isRunning && startTime && !hasTimeLimit) {
      intervalId = setInterval(() => {
        const currentElapsed = Math.floor((Date.now() - startTime) / 1000);
        setElapsedTime(currentElapsed);
      }, 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isRunning, startTime, hasTimeLimit]);

  const getTimeSpent = useCallback(() => {
    if (!startTime) return 0;
    return Math.floor((Date.now() - startTime) / 1000);
  }, [startTime]);

  return {
    elapsedTime,
    getTimeSpent,
  };
};
