import { useState, useEffect, useContext } from "react";
import {
  Table,
  UnstyledButton,
  Group,
  Text,
  Center,
  TextInput,
  rem,
  Box,
  Flex,
  Title,
  Avatar,
  ScrollArea,
  Stack,
  Button,
} from "@mantine/core";
import {
  IconSelector,
  IconChevronDown,
  IconChevronUp,
  IconSearch,
} from "@tabler/icons-react";
import classes from "../css/TableSort.module.css";
import useFetchData from "../hooks/useFetchData";
import { UserContext } from "../context/UserContext";
import ActiveUsersStats from "../Components/ActiveUsersStats";
import { useNavigate } from "react-router-dom";

interface UserData {
  id: number;
  createdAt: string;
  pseudo: string;
  profilePictureUrl: string;
  updatedAt: string; // only for admin
  isPublicProfile: boolean; // only for admin
}

interface ThProps {
  children: React.ReactNode;
  reversed: boolean;
  sorted: boolean;
  onSort(): void;
}

function Th({ children, reversed, sorted, onSort }: ThProps) {
  const Icon = sorted
    ? reversed
      ? IconChevronUp
      : IconChevronDown
    : IconSelector;
  return (
    <Table.Th className={classes.th}>
      <UnstyledButton onClick={onSort} className={classes.control}>
        <Group justify="space-between">
          <Text fw={500} fz="sm">
            {children}
          </Text>
          <Center className={classes.icon}>
            <Icon style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
          </Center>
        </Group>
      </UnstyledButton>
    </Table.Th>
  );
}

function filterData(data: UserData[], search: string) {
  const query = search.toLowerCase().trim();
  return data.filter((item) =>
    (Object.keys(item) as (keyof UserData)[]).some((key) => {
      const value = item[key];
      return value != null && value.toString().toLowerCase().includes(query);
    })
  );
}

function sortData(
  data: UserData[],
  payload: { sortBy: keyof UserData | null; reversed: boolean; search: string }
) {
  const { sortBy } = payload;

  if (!sortBy) {
    return filterData(data, payload.search);
  }

  return filterData(
    [...data].sort((a, b) => {
      const valueA = a[sortBy];
      const valueB = b[sortBy];

      if (valueA == null && valueB == null) return 0;
      if (valueA == null) return payload.reversed ? 1 : -1;
      if (valueB == null) return payload.reversed ? -1 : 1;

      if (sortBy === "createdAt" || sortBy === "updatedAt") {
        const dateA = new Date(valueA as string).getTime();
        const dateB = new Date(valueB as string).getTime();
        return payload.reversed ? dateB - dateA : dateA - dateB;
      }

      const stringA = valueA.toString();
      const stringB = valueB.toString();

      return payload.reversed
        ? stringB.localeCompare(stringA)
        : stringA.localeCompare(stringB);
    }),
    payload.search
  );
}

export function Users() {
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState<keyof UserData | null>("updatedAt");
  const [reverseSortDirection, setReverseSortDirection] = useState(true);
  const [sortedData, setSortedData] = useState<UserData[]>([]);
  const { user } = useContext(UserContext);
  const { data, error, isLoading } = useFetchData("users", "/user/users");
  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      setSortedData(
        sortData(data, { sortBy, reversed: reverseSortDirection, search })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const setSorting = (field: keyof UserData) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setSortedData(sortData(data || [], { sortBy: field, reversed, search }));
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSearch(value);
    setSortedData(
      sortData(data || [], {
        sortBy,
        reversed: reverseSortDirection,
        search: value,
      })
    );
  };

  const rows = sortedData.map((row) => (
    <Table.Tr
      key={row.id}
      onClick={() => navigate("/user/" + row.pseudo)}
      style={{ cursor: "pointer" }}
    >
      <Table.Td>
        <Group gap="sm">
          <Avatar
            size={26}
            src={row?.profilePictureUrl}
            radius={26}
            color="theme"
          >
            {row?.pseudo ? row.pseudo.slice(0, 1).toUpperCase() : null}
          </Avatar>
          <Text size="sm" fw={500}>
            {row.pseudo}
          </Text>
        </Group>
      </Table.Td>
      <Table.Td>{new Date(row.createdAt).toLocaleDateString()}</Table.Td>
      {user?.role === "admin" && (
        <Table.Td>
          {row.id} {!row.isPublicProfile && "🔒"}
        </Table.Td>
      )}
      {user?.role === "admin" && (
        <Table.Td>
          {row.updatedAt &&
            new Date(row.updatedAt).toLocaleString("fr-FR", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
            })}
        </Table.Td>
      )}
      <Table.Td>
        <Button variant="light" size="xs">
          Voir le profil
        </Button>
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <Box p={{ base: 8, sm: 16 }}>
      <Flex
        direction={{ base: "column", sm: "row" }}
        justify="space-between"
        align="center"
        mb="md"
      >
        <Stack mb={{ base: "md", sm: 0 }} gap="xs">
          <Title order={2}>
            Annuaire des utilisateurs {user?.role !== "admin" && "publiques"}
          </Title>
          {user?.role === "admin" && <ActiveUsersStats users={data || []} />}
        </Stack>
        <TextInput
          placeholder="Rechercher dans l'annuaire"
          leftSection={
            <IconSearch
              style={{ width: rem(16), height: rem(16) }}
              stroke={1.5}
            />
          }
          value={search}
          onChange={handleSearchChange}
          w={{ base: "100%", sm: 300 }}
        />
      </Flex>

      <ScrollArea>
        <Table
          miw={700}
          horizontalSpacing="md"
          verticalSpacing="xs"
          layout="fixed"
        >
          <Table.Thead>
            <Table.Tr>
              <Th
                sorted={sortBy === "pseudo"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("pseudo")}
              >
                Pseudo
              </Th>
              <Th
                sorted={sortBy === "createdAt"}
                reversed={reverseSortDirection}
                onSort={() => setSorting("createdAt")}
              >
                Date d'inscription
              </Th>
              {user?.role === "admin" && (
                <Th
                  sorted={sortBy === "id"}
                  reversed={reverseSortDirection}
                  onSort={() => setSorting("id")}
                >
                  ID & privé
                </Th>
              )}
              {user?.role === "admin" && (
                <Th
                  sorted={sortBy === "updatedAt"}
                  reversed={reverseSortDirection}
                  onSort={() => setSorting("updatedAt")}
                >
                  Maj
                </Th>
              )}
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {rows.length > 0 ? (
              rows
            ) : (
              <Table.Tr>
                <Table.Td colSpan={user?.role === "admin" ? 5 : 3}>
                  <Text fw={500} ta="center">
                    Nothing found
                  </Text>
                </Table.Td>
              </Table.Tr>
            )}
          </Table.Tbody>
        </Table>
      </ScrollArea>
    </Box>
  );
}
