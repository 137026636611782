import React from "react";
import { Card, Text, Badge, Button, Group, Spoiler } from "@mantine/core";
import { MiniGame } from "../dto/user";
//@ts-ignore
import DOMPurify from "dompurify";
import { getMiniGameCategory } from "../Pages/AdminMiniGames";
import DisplayResult from "./DisplayResult";

interface MiniGameCardProps {
  miniGame: MiniGame;
  onPlay: (miniGameId: number) => void;
  isHorizontalScroll?: boolean;
  isAdmin?: boolean;
}

const MiniGameCard: React.FC<MiniGameCardProps> = ({
  miniGame,
  onPlay,
  isHorizontalScroll,
  isAdmin,
}) => {
  const sanitizedDescription = DOMPurify.sanitize(miniGame.description as any);
  if (!isAdmin && !miniGame.activated) return null;

  const highestGrade = miniGame.results && miniGame.results[0]?.score;

  return (
    <Card
      shadow="sm"
      padding="lg"
      radius="md"
      withBorder
      style={
        isHorizontalScroll
          ? { width: 280 }
          : { height: "100%", display: "flex", flexDirection: "column" }
      }
    >
      <Group justify="space-between" mb="xs">
        <Text fw={500}>{miniGame.title}</Text>
        <Group>
          {miniGame.timeLimit !== 0 && (
            <Badge color="blue" variant="light">
              {((miniGame.timeLimit ?? 0) / 60) % 1 === 0
                ? (miniGame.timeLimit ?? 0) / 60
                : ((miniGame.timeLimit ?? 0) / 60).toFixed(2)}{" "}
              minutes
            </Badge>
          )}
          <Badge color="yellow" variant="light">
            {getMiniGameCategory(miniGame.category)}
          </Badge>
          <DisplayResult score={highestGrade} ml={4} />
        </Group>
      </Group>

      <Spoiler
        maxHeight={100}
        showLabel="Voir plus"
        hideLabel="Cacher"
        style={{ flexGrow: 1 }}
      >
        <div
          dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
          style={{ fontSize: "14px", color: "var(--mantine-color-dimmed)" }}
        />
      </Spoiler>

      <Button
        variant="light"
        color={isAdmin && !miniGame.activated ? "red" : "blue"}
        fullWidth
        mt="md"
        radius="md"
        onClick={() => onPlay(miniGame.id ?? 0)}
      >
        {isAdmin && !miniGame.activated ? "Jeu désactivé" : "Jouer au Mini-Jeu"}
      </Button>
    </Card>
  );
};

export default MiniGameCard;
