import React, { useContext, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  Accordion,
  Box,
  Text,
  Title,
  Button,
  Modal,
  ScrollArea,
  TypographyStylesProvider,
  Paper,
  Group,
  useMantineTheme,
  useMantineColorScheme,
} from "@mantine/core";
import { format } from "date-fns";
import { useApi } from "../useApi";
import { MiniGameResult } from "../dto/user";
import { IconCheck, IconX } from "@tabler/icons-react";
import DOMPurify from "dompurify";
import DisplayResult from "./DisplayResult";
import { UserContext } from "../context/UserContext";
import CopyButton from "./CopyButton";

interface ChatMessage {
  role: string;
  content: string;
}

interface QuizAnswer {
  questionId: number;
  userAnswer: string;
  correctAnswer: string;
  isCorrect: boolean;
}

const ChatHistoryModal = ({
  opened,
  onClose,
  selectedChat,
  isDark,
  theme,
}: {
  opened: boolean;
  onClose: () => void;
  selectedChat: ChatMessage[] | null;
  isDark: boolean;
  theme: any;
}) => {
  const chatContent = selectedChat
    ?.map(
      (msg) =>
        `${
          msg.role === "user"
            ? "Utilisateur"
            : msg.role === "system"
            ? "Système"
            : "Assistant"
        }:\n${msg.content}`
    )
    .join("\n\n");

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      size="lg"
      title={
        <Group justify="space-between" w="100%">
          <Title order={4}>Historique du chat</Title>
          <CopyButton value={chatContent} />
        </Group>
      }
    >
      <ScrollArea h={400}>
        {selectedChat?.map((message, index) => (
          <Box
            key={index}
            mb={10}
            p={10}
            style={{
              backgroundColor:
                message.role === "user"
                  ? isDark
                    ? theme.colors.dark[6]
                    : theme.colors.gray[1]
                  : message.role === "system"
                  ? isDark
                    ? theme.colors.red[9]
                    : theme.colors.red[1]
                  : isDark
                  ? theme.colors.blue[9]
                  : theme.colors.blue[1],
              borderRadius: theme.radius.sm,
            }}
          >
            <Text fw={700}>
              {message.role === "user"
                ? "Vous"
                : message.role === "system"
                ? "Système"
                : "Assistant"}
            </Text>
            <TypographyStylesProvider>
              <div dangerouslySetInnerHTML={{ __html: message.content }} />
            </TypographyStylesProvider>
          </Box>
        ))}
      </ScrollArea>
    </Modal>
  );
};

const QuizAnswerItem = ({
  answer,
  index,
  theme,
  isDark,
}: {
  answer: QuizAnswer;
  index: number;
  theme: any;
  isDark: boolean;
}) => (
  <Paper p="md" withBorder mb="md">
    <Text fw={600}>Question {index + 1}:</Text>
    <Text mt="xs">Votre réponse: {answer.userAnswer}</Text>
    <Text mt="xs">Réponse correcte: {answer.correctAnswer}</Text>
    {answer.isCorrect ? (
      <Group mt="xs">
        <IconCheck color={theme.colors.green[isDark ? 4 : 6]} />
        <Text c={theme.colors.green[isDark ? 4 : 6]}>Correct</Text>
      </Group>
    ) : (
      <Group mt="xs">
        <IconX color={theme.colors.red[isDark ? 4 : 6]} />
        <Text c={theme.colors.red[isDark ? 4 : 6]}>Incorrect</Text>
      </Group>
    )}
  </Paper>
);

const QuizResultsModal = ({
  opened,
  onClose,
  answers,
  theme,
  isDark,
}: {
  opened: boolean;
  onClose: () => void;
  answers: QuizAnswer[] | null;
  theme: any;
  isDark: boolean;
}) => (
  <Modal opened={opened} onClose={onClose} title="Résultats du Quiz" size="lg">
    <ScrollArea h={400}>
      {answers?.map((answer, index) => (
        <QuizAnswerItem
          key={index}
          answer={answer}
          index={index}
          theme={theme}
          isDark={isDark}
        />
      ))}
    </ScrollArea>
  </Modal>
);

const GameResultItem = ({
  result,
  isAdmin,
  user,
  onOpenChat,
  onOpenQuiz,
}: {
  result: MiniGameResult;
  isAdmin?: boolean;
  user: any;
  onOpenChat: (chat: any) => void;
  onOpenQuiz: (quiz: any) => void;
}) => (
  <Accordion.Item value={`game-${result.id}`}>
    <Accordion.Control>
      {result.miniGame?.title || "Titre inconnu"}
      <DisplayResult score={result.score} ml="sm" />
      {result.score !== -1 && user?.role === "admin" && (
        <> - Score: {result.score?.toFixed(2) || "N/A"}</>
      )}
      {isAdmin &&
        ` - ${
          result.createdAt
            ? format(new Date(result.createdAt), "dd/MM HH:mm")
            : "Date Inconnue"
        } - ${result.user?.pseudo || "Unknown"}`}
    </Accordion.Control>
    <Accordion.Panel>
      <Text>
        Date:{" "}
        {result.createdAt
          ? format(new Date(result.createdAt), "dd/MM/yyyy HH:mm")
          : "Date Inconnue"}
      </Text>
      {(result.timeSpent ?? 0) > 0 && isAdmin && (
        <Text>Temps passé: {result.timeSpent} secondes</Text>
      )}
      <Text fw={700} mt={10} mb={5}>
        Feedback:
      </Text>
      <TypographyStylesProvider>
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              result.feedback || "Aucun feedback"
            ).replace(/\n/g, "<br>"),
          }}
        />
      </TypographyStylesProvider>
      <Group mt={10}>
        {result.chatHistory && (
          <Button onClick={() => onOpenChat(result.chatHistory)}>
            Voir l'historique du chat
          </Button>
        )}
        {result.quizAnswers && (
          <Button onClick={() => onOpenQuiz(result.quizAnswers)}>
            Voir les réponses du quiz
          </Button>
        )}
      </Group>
    </Accordion.Panel>
  </Accordion.Item>
);

const MiniGameResultsAccordion: React.FC<{
  skillId: number;
  isAdmin?: boolean;
}> = ({ skillId, isAdmin }) => {
  const { user } = useContext(UserContext);
  const api = useApi();
  const [selectedChat, setSelectedChat] = useState<ChatMessage[] | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedQuizAnswers, setSelectedQuizAnswers] = useState<
    QuizAnswer[] | null
  >(null);
  const [isQuizModalOpen, setIsQuizModalOpen] = useState(false);
  const theme = useMantineTheme();
  const { colorScheme } = useMantineColorScheme();
  const isDark = colorScheme === "dark";

  const {
    data: miniGameResults,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["miniGameResults", skillId, isAdmin],
    queryFn: () =>
      isAdmin
        ? api.get("/mini-games/results/all").then((res) => res.data)
        : api
            .get(`/mini-games/results/skill/${skillId}`)
            .then((res) => res.data),
    enabled: isAdmin || !!skillId,
  });

  const openChatModal = (chatHistory: any) => {
    try {
      const parsedChat = JSON.parse(chatHistory) as ChatMessage[];
      setSelectedChat(parsedChat);
      setIsModalOpen(true);
    } catch (e) {
      console.error("Failed to parse chat history:", e);
    }
  };

  const openQuizModal = (quizAnswers: any) => {
    try {
      const parsedAnswers = JSON.parse(quizAnswers) as QuizAnswer[];
      setSelectedQuizAnswers(parsedAnswers);
      setIsQuizModalOpen(true);
    } catch (e) {
      console.error("Failed to parse quiz answers:", e);
    }
  };

  if (isLoading) return <Text>Chargement des résultats des mini-jeux...</Text>;
  if (error)
    return (
      <Text c="red">
        Erreur lors du chargement des résultats des mini-jeux:{" "}
        {(error as Error).message}
      </Text>
    );
  if (!miniGameResults?.length)
    return (
      <Text>Aucun résultat de mini-jeu disponible pour cette compétence.</Text>
    );

  return (
    <Box>
      <Title order={3} mb={10}>
        {isAdmin
          ? "Tous les résultats des Mini-Jeux"
          : "Résultats des Mini-Jeux"}
      </Title>

      <Accordion>
        {miniGameResults.map((result: MiniGameResult) => (
          <GameResultItem
            key={result.id}
            result={result}
            isAdmin={isAdmin}
            user={user}
            onOpenChat={openChatModal}
            onOpenQuiz={openQuizModal}
          />
        ))}
      </Accordion>

      <ChatHistoryModal
        opened={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        selectedChat={selectedChat}
        isDark={isDark}
        theme={theme}
      />

      <QuizResultsModal
        opened={isQuizModalOpen}
        onClose={() => setIsQuizModalOpen(false)}
        answers={selectedQuizAnswers}
        theme={theme}
        isDark={isDark}
      />
    </Box>
  );
};

export default MiniGameResultsAccordion;
