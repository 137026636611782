import { useContext, useState } from "react";
import { UserContext } from "./context/UserContext";
import { AppShell as MantineAppShell, useMantineTheme } from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { Header } from "./Components/Header";
import Footer from "./Components/Footer";
import FooterMenuMobile from "./Components/FooterMenuMobile";
import NavBarGroup from "./Components/NavBarGroup";

export const displayUnused = window.location.host === "localhost:3000";

export default function AppShell({ children }: { children: React.ReactNode }) {
  const { isLoggedIn } = useContext(UserContext);
  const [opened, { toggle, close }] = useDisclosure();
  const [
    openedLoginRegister,
    { open: openLoginRegister, close: closeLoginRegister },
  ] = useDisclosure(false);
  const [typeLoginRegister, setLoginRegister] = useState<"login" | "register">(
    "register"
  );
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

  return (
    <MantineAppShell
      header={{ height: 60 }}
      footer={{ height: isMobile && !opened ? 50 : 0 }}
      navbar={{
        width: 220,
        breakpoint: "sm",
        collapsed: { mobile: !opened, desktop: !isLoggedIn && !opened },
      }}
      padding={isLoggedIn ? undefined : "md"}
      style={{
        root: {
          height: "100dvh",
          display: "flex",
          flexDirection: "column",
        },
        main: {
          flex: 1,
          overflowY: "auto",
        },
        height: "100dvh",
      }}
    >
      <MantineAppShell.Header>
        <Header
          opened={opened}
          close={close}
          toggle={toggle}
          typeLoginRegister={typeLoginRegister}
          setLoginRegister={setLoginRegister}
          openedLoginRegister={openedLoginRegister}
          closeLoginRegister={closeLoginRegister}
          openLoginRegister={openLoginRegister}
        />
      </MantineAppShell.Header>

      <MantineAppShell.Navbar>
        <NavBarGroup
          close={close}
          openLoginRegister={openLoginRegister}
          setLoginRegister={setLoginRegister}
        />
      </MantineAppShell.Navbar>

      <MantineAppShell.Main
        style={{
          padding: isLoggedIn ? undefined : 0,
          paddingTop: undefined,
          paddingBottom: isLoggedIn ? undefined : 0,
        }}
      >
        {children}
        {!isLoggedIn && <Footer />}
      </MantineAppShell.Main>

      {isLoggedIn &&
        !window.location.pathname.includes("/mini-game/") &&
        isMobile && (
          <MantineAppShell.Footer
            style={{
              position: "fixed",
              bottom: 0,
              left: 0,
              right: 0,
              height: "50px",
            }}
          >
            <FooterMenuMobile />
          </MantineAppShell.Footer>
        )}
    </MantineAppShell>
  );
}
