import { Box, Title } from "@mantine/core";
import React, { useContext } from "react";
import { UserContext } from "../context/UserContext";
import { useStreak } from "../hooks/useStreak";
import StreakReminder from "../Components/StreakReminder";
import BooksProgress from "../Components/BooksProgress";
// import WelcomeMessage from "../Components/WelcomeMessage";

const Home: React.FC = () => {
  const { user } = useContext(UserContext);
  const { currentStreak, playedToday, isLoading, error, results } = useStreak();

  return (
    <Box p={16}>
      <Title>Bienvenue {user?.pseudo}</Title>
      {/* <WelcomeMessage /> */}

      <StreakReminder
        currentStreak={currentStreak}
        playedToday={playedToday}
        results={results}
        isLoading={isLoading}
        error={error}
      />

      <BooksProgress />
    </Box>
  );
};

export default Home;
