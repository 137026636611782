import React from "react";
import { Group, Paper, Text, Button } from "@mantine/core";
// import { getMiniGameCategory } from "../Pages/AdminMiniGames";
import DisplayResult from "./DisplayResult";
import { MiniGame } from "../dto/user";

interface MiniGameRowProps {
  miniGame: MiniGame;
  onPlay: (miniGameId: number) => void;
  isAdmin?: boolean;
}

const MiniGameRow: React.FC<MiniGameRowProps> = ({
  miniGame,
  onPlay,
  isAdmin,
}) => {
  if (!isAdmin && !miniGame.activated) return null;

  const highestGrade = miniGame.results && miniGame.results[0]?.score;

  return (
    <Paper withBorder p="md" radius="md" mb="sm">
      <Group justify="space-between" wrap="nowrap">
        <Group>
          <Text fw={500}>{miniGame.title}</Text>
          {/* {miniGame.timeLimit !== 0 && (
            <Badge color="blue" variant="light">
              {((miniGame.timeLimit ?? 0) / 60) % 1 === 0
                ? (miniGame.timeLimit ?? 0) / 60
                : ((miniGame.timeLimit ?? 0) / 60).toFixed(2)}{" "}
              minutes
            </Badge>
          )}
          <Badge color="yellow" variant="light">
            {getMiniGameCategory(miniGame.category)}
          </Badge> */}
          <DisplayResult score={highestGrade} light />
        </Group>
        <Button
          variant="light"
          color={isAdmin && !miniGame.activated ? "red" : "blue"}
          onClick={() => onPlay(miniGame.id ?? 0)}
          miw="80px"
        >
          Jouer
        </Button>
      </Group>
    </Paper>
  );
};

export default MiniGameRow;
