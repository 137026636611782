import { CopyButton, ActionIcon, Tooltip, rem } from "@mantine/core";
import { IconCopy, IconCheck } from "@tabler/icons-react";
import { useState } from "react";

const CopyButon = ({
  value,
  onCopy,
  timeout = 2000,
}: {
  value?: string;
  onCopy?: () => Promise<void>;
  timeout?: number;
}) => {
  const [copied, setCopied] = useState(false);

  const handleCopyClick = async () => {
    if (!onCopy) return;

    await onCopy();
    setCopied(true);
    setTimeout(() => setCopied(false), timeout);
  };

  const renderButton = ({
    copied,
    copy,
  }: {
    copied: boolean;
    copy?: () => void;
  }) => (
    <Tooltip label={copied ? "Copied" : "Copy"} withArrow position="right">
      <ActionIcon
        color={copied ? "teal" : "gray"}
        variant="subtle"
        onClick={onCopy ? handleCopyClick : copy}
      >
        {copied ? (
          <IconCheck style={{ width: rem(16) }} />
        ) : (
          <IconCopy style={{ width: rem(16) }} />
        )}
      </ActionIcon>
    </Tooltip>
  );

  return onCopy ? (
    renderButton({ copied })
  ) : (
    <CopyButton value={value ?? ""} timeout={timeout}>
      {renderButton}
    </CopyButton>
  );
};

export default CopyButon;
