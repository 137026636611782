import React from "react";
import { Paper, Text, Stack } from "@mantine/core";
import ReactMarkdown from "react-markdown";
import { ChatCompletionMessageParam } from "openai/resources";

interface ChatMessageProps {
  message: ChatCompletionMessageParam;
  getAssistantMessageStyle: () => React.CSSProperties;
  getUserMessageStyle: () => React.CSSProperties;
}

const ChatMessage = ({
  message,
  getAssistantMessageStyle,
  getUserMessageStyle,
}: ChatMessageProps) => {
  const parts =
    typeof message.content === "string" ? message.content.split("|") : [""];

  console.log("parts", parts);

  const mainContent = parts[0];
  const contextContent = parts[1];
  const isAssistant = message.role === "assistant";

  return (
    <Stack gap="xs">
      <Paper
        p="xs"
        pt={0}
        pb={0}
        withBorder
        style={isAssistant ? getAssistantMessageStyle() : getUserMessageStyle()}
      >
        <ReactMarkdown>{mainContent}</ReactMarkdown>
      </Paper>
      {contextContent && (
        <Text
          size="sm"
          c="dimmed"
          style={{
            alignSelf: isAssistant ? "flex-start" : "flex-end",
            maxWidth: "80%",
            paddingLeft: isAssistant ? "xs" : 0,
            paddingRight: isAssistant ? 0 : "xs",
          }}
        >
          {contextContent.trim()}
        </Text>
      )}
    </Stack>
  );
};

export default ChatMessage;
