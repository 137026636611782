import React, { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../useApi";
import { Box, Title, Text, Button, Group } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { MiniGame } from "../dto/user";
import MiniGameCard from "../Components/MiniGameCard";
import { UserContext } from "../context/UserContext";
import { IconDice } from "@tabler/icons-react";

const MiniGames: React.FC = () => {
  const api = useApi();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const {
    data: miniGames,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["miniGames"],
    queryFn: () => api.get("/mini-games").then((res) => res.data),
  });

  const handlePlayMiniGame = (miniGameId: number) => {
    console.log(`Démarrage du mini-jeu avec l'id : ${miniGameId}`);
    navigate(`/mini-game/${miniGameId}`);
  };

  const handlePlayRandomMiniGame = () => {
    if (!miniGames) return;

    const activeMiniGames = miniGames.filter(
      (game: MiniGame) => game.activated
    );

    if (activeMiniGames.length === 0) {
      return;
    }

    const randomIndex = Math.floor(Math.random() * activeMiniGames.length);
    const randomMiniGame = activeMiniGames[randomIndex];
    handlePlayMiniGame(randomMiniGame.id);
  };

  if (isLoading) return <Text>Chargement des mini-jeux...</Text>;
  if (error)
    return <Text c="red">Erreur lors du chargement des mini-jeux.</Text>;

  const filteredMiniGames = miniGames.filter(
    (game: MiniGame) => user?.role === "admin" || game.activated
  );

  return (
    <Box p={16}>
      <Group justify="space-between" align="center" mb="lg">
        <Title order={1}>Mini-Jeux</Title>
        <Button
          onClick={handlePlayRandomMiniGame}
          leftSection={<IconDice size={20} />}
          variant="gradient"
          gradient={{ from: "indigo", to: "violet" }}
        >
          Jouer à un mini-jeu aléatoire
        </Button>
      </Group>

      <Box
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(280px, 1fr))",
          gap: "1rem",
        }}
      >
        {filteredMiniGames.map((game: MiniGame) => (
          <MiniGameCard
            key={game.id}
            miniGame={game}
            onPlay={handlePlayMiniGame}
            isAdmin={user?.role === "admin"}
          />
        ))}
      </Box>
    </Box>
  );
};

export default MiniGames;
