import React, { useState, useEffect } from "react";
import {
  Box,
  Title,
  Table,
  Button,
  Modal,
  TextInput,
  Textarea,
  Switch,
  Group,
  Avatar,
  Text,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useApi } from "../useApi";
import { Interlocutor } from "../dto/user";

const InterlocutorManagement: React.FC = () => {
  const [interlocutors, setInterlocutors] = useState<Interlocutor[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingInterlocutor, setEditingInterlocutor] =
    useState<Interlocutor | null>(null);
  const api = useApi();

  const form = useForm({
    initialValues: {
      name: "",
      avatarUrl: "",
      prompt: "",
      userCanSelect: false,
    },
    validate: {
      name: (value) => (value.length > 0 ? null : "Le nom est requis"),
      prompt: (value) => (value.length > 0 ? null : "Le prompt est requis"),
    },
  });

  useEffect(() => {
    fetchInterlocutors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchInterlocutors = async () => {
    try {
      const response = await api.get("/interlocutors");
      setInterlocutors(response.data);
    } catch (error) {
      console.error(
        "Erreur lors de la récupération des interlocuteurs:",
        error
      );
    }
  };

  const handleSubmit = async (values: typeof form.values) => {
    try {
      if (editingInterlocutor) {
        await api.put(`/interlocutors/${editingInterlocutor.id}`, values);
      } else {
        await api.post("/interlocutors", values);
      }
      fetchInterlocutors();
      setIsModalOpen(false);
      form.reset();
      setEditingInterlocutor(null);
    } catch (error) {
      console.error("Erreur lors de la sauvegarde de l'interlocuteur:", error);
    }
  };

  const handleEdit = (interlocutor: Interlocutor) => {
    setEditingInterlocutor(interlocutor);
    form.setValues({
      name: interlocutor.name,
      avatarUrl: interlocutor.avatarUrl || "",
      prompt: interlocutor.prompt,
      userCanSelect: interlocutor.userCanSelect,
    });
    setIsModalOpen(true);
  };

  const handleDelete = async (id: number) => {
    if (
      window.confirm("Êtes-vous sûr de vouloir supprimer cet interlocuteur ?")
    ) {
      try {
        await api.delete(`/interlocutors/${id}`);
        fetchInterlocutors();
      } catch (error) {
        console.error(
          "Erreur lors de la suppression de l'interlocuteur:",
          error
        );
      }
    }
  };

  const getInitials = (name: string) => {
    return name
      .split(" ")
      .map((n) => n[0])
      .join("")
      .toUpperCase();
  };

  return (
    <Box p={16}>
      <Title order={1} mb="lg">
        Gestion des Interlocuteurs
      </Title>
      <Button onClick={() => setIsModalOpen(true)} mb="md">
        Ajouter un interlocuteur
      </Button>

      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Nom</Table.Th>
            <Table.Th>Prompt</Table.Th>
            <Table.Th>Sélectionnable</Table.Th>
            <Table.Th>Actions</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {interlocutors.map((interlocutor) => (
            <Table.Tr key={interlocutor.id}>
              <Table.Td>
                <Group gap="sm">
                  <Avatar
                    src={interlocutor.avatarUrl}
                    size="sm"
                    radius="xl"
                    color="blue"
                  >
                    {getInitials(interlocutor.name ?? "")}
                  </Avatar>
                  {interlocutor.name}
                </Group>
              </Table.Td>
              <Table.Td>
                <Text
                  truncate="end"
                  style={{ maxWidth: "400px" }}
                  title={interlocutor.prompt}
                >
                  {interlocutor.prompt}
                </Text>
              </Table.Td>
              <Table.Td>{interlocutor.userCanSelect ? "Oui" : "Non"}</Table.Td>
              <Table.Td>
                <Group gap="xs">
                  <Button onClick={() => handleEdit(interlocutor)} size="xs">
                    Modifier
                  </Button>
                  <Button
                    onClick={() => handleDelete(interlocutor.id)}
                    color="red"
                    size="xs"
                  >
                    Supprimer
                  </Button>
                </Group>
              </Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>

      <Modal
        opened={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setEditingInterlocutor(null);
          form.reset();
        }}
        title={
          editingInterlocutor
            ? "Modifier l'interlocuteur"
            : "Ajouter un interlocuteur"
        }
      >
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <TextInput
            label="Nom"
            placeholder="Entrez le nom de l'interlocuteur"
            {...form.getInputProps("name")}
            required
          />
          <TextInput
            label="URL de l'avatar"
            placeholder="Entrez l'URL de l'avatar"
            {...form.getInputProps("avatarUrl")}
            mt="md"
          />
          <Textarea
            label="Prompt"
            placeholder="Entrez le prompt de l'interlocuteur"
            {...form.getInputProps("prompt")}
            mt="md"
            required
          />
          <Switch
            label="Sélectionnable par les utilisateurs"
            {...form.getInputProps("userCanSelect", { type: "checkbox" })}
            mt="md"
          />
          <Button type="submit" mt="md">
            {editingInterlocutor ? "Modifier" : "Ajouter"}
          </Button>
        </form>
      </Modal>
    </Box>
  );
};

export default InterlocutorManagement;
