import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import {
  Button,
  Stack,
  Paper,
  Textarea,
  LoadingOverlay,
  ScrollArea,
  Loader,
  useMantineColorScheme,
  useMantineTheme,
  Group,
  ActionIcon,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { ChatCompletionMessageParam } from "openai/resources";
import { io, Socket } from "socket.io-client";
import { baseUrl } from "../../useApi";
import { debounce } from "lodash";
import { useMediaQuery } from "@mantine/hooks";
import ChatHeaderAvatar from "./ChatHeaderAvatar";
import { useNavigate } from "react-router-dom";
import { userLocalDate } from "../../utils/userLocalDate";
import { Interlocutor } from "../../dto/user";
import DOMPurify from "dompurify";
import GameResult from "./GameResult";
import { useElapsedTime } from "../../hooks/useElapsedTime";
import { IconSend } from "@tabler/icons-react";
import ChatMessage from "./ChatMessage";

interface ChatGameProps {
  gameData: {
    id: number;
    description: string;
    timeLimit: number;
    skillId: number;
    prompt: string;
    interlocutor: Interlocutor;
  };
  gameState: "not_started" | "started" | "finished" | "submitted";
  setGameState: React.Dispatch<
    React.SetStateAction<"not_started" | "started" | "finished" | "submitted">
  >;
  timeLeft: number;
  setTimeLeft: React.Dispatch<React.SetStateAction<number | undefined>>;
  marginLeft: number | undefined;
}

const ChatGame: React.FC<ChatGameProps> = ({
  gameData,
  gameState,
  setGameState,
  timeLeft,
  setTimeLeft,
  marginLeft,
}) => {
  const { colorScheme } = useMantineColorScheme();
  const theme = useMantineTheme();
  const [score, setScore] = useState<number | null>(null);
  const [feedback, setFeedback] = useState("");
  const [chatHistory, setChatHistory] = useState<ChatCompletionMessageParam[]>(
    []
  );
  const [currentMessage, setCurrentMessage] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [socket, setSocket] = useState<Socket | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [startTime, setStartTime] = useState<number | null>(null);
  const [isInitialMessageComplete, setIsInitialMessageComplete] =
    useState(false);
  const chatAreaRef = useRef<HTMLDivElement>(null);
  const scrollAreaRef = useRef<HTMLDivElement>(null);
  const isMobile =
    useMediaQuery(`(max-width: ${theme.breakpoints.md})`) ||
    (gameState !== "started" && gameState !== "finished");
  const navigate = useNavigate();
  const hasTimeLimit = gameData.timeLimit > 0;

  const { getTimeSpent } = useElapsedTime({
    isRunning: gameState === "started",
    hasTimeLimit,
  });

  const debouncedSetIsTyping = useMemo(
    () => debounce(() => setIsTyping(false), 1000),
    []
  );

  const scrollToBottom = () => {
    if (scrollAreaRef.current) {
      scrollAreaRef.current.scrollTo({
        top: scrollAreaRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);

  const setupSocket = useCallback(() => {
    const token = JSON.parse(localStorage.getItem("user") || "{}").accessToken;
    const newSocket: Socket = io(baseUrl, {
      withCredentials: true,
      auth: { token },
    });

    newSocket.on("connect", () => {
      console.log("Connected to WebSocket");
    });

    newSocket.on("chatResponse", (chunk: string) => {
      setChatHistory((prev: any) => {
        const lastMessage = prev[prev.length - 1];
        let updatedContent;
        if (lastMessage && lastMessage.role === "assistant") {
          updatedContent = lastMessage.content + chunk;
        } else {
          updatedContent = chunk;
        }

        let endChatDetected = false;
        if (updatedContent.includes("[[END_CHAT]]")) {
          updatedContent = updatedContent.replace("[[END_CHAT]]", "");
          endChatDetected = true;
        }

        const newChatHistory = [
          ...prev.slice(
            0,
            lastMessage && lastMessage.role === "assistant" ? -1 : prev.length
          ),
          { role: "assistant", content: updatedContent },
        ];

        if (endChatDetected) {
          setGameState("finished");
        }
        return newChatHistory;
      });
      setIsTyping(true);
      debouncedSetIsTyping();
    });

    newSocket.on("chatComplete", () => {
      setIsTyping(false);
      setIsInitialMessageComplete(true);
      setIsSubmitting(false);
    });

    newSocket.on("chatError", (error) => {
      console.error("Error in chat:", error);
      notifications.show({
        title: "Erreur",
        message: "Une erreur est survenue lors de la conversation.",
        color: "red",
      });
      setIsTyping(false);
      setIsSubmitting(false);
    });

    newSocket.on(
      "chatGameResultSubmitted",
      (result: { score: number; feedback: string }) => {
        setScore(result.score);
        setFeedback(result.feedback);
        setIsSubmitting(false);
        setGameState("submitted");
        if (result.score > 0) {
          notifications.show({
            title: "Résultat enregistré",
            message: "Votre partie a été sauvegardée avec succès.",
            color: "green",
          });
          // } else {
          //   notifications.show({
          //     title: "Résultat non enregistré",
          //     message:
          //       "Votre partie n'a pas été sauvegardée car le score est de 0.",
          //     color: "yellow",
          //   });
        }
      }
    );

    newSocket.on("chatGameResultError", (error: { message: string }) => {
      setIsSubmitting(false);
      setGameState("finished");
      notifications.show({
        title: "Erreur",
        message: error.message,
        color: "red",
      });
    });

    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, [setGameState, debouncedSetIsTyping]);

  useEffect(() => {
    const cleanup = setupSocket();
    return () => {
      cleanup();
      debouncedSetIsTyping.cancel();
    };
  }, [setupSocket, debouncedSetIsTyping]);

  // useEffect(() => {
  //   if (gameState === "started" && startTime === null) {
  //     setStartTime(Date.now());
  //   }
  // }, [gameState, startTime]);

  useEffect(() => {
    if (gameState === "started" && timeLeft === 0 && gameData.timeLimit > 0) {
      setGameState("finished");
    }
  }, [timeLeft, gameState, gameData.timeLimit, setGameState]);

  useEffect(() => {
    if (gameState === "finished" && !isSubmitting) {
      submitGame();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameState, isSubmitting]);

  const initializeGame = useCallback(() => {
    setStartTime(Date.now());
    setTimeLeft(gameData.timeLimit);
    setIsTyping(true);
    setIsInitialMessageComplete(false);
    setIsSubmitting(true);
    if (socket) {
      socket.emit("startChatGame", { miniGameId: gameData.id });
    }
  }, [gameData, socket, setTimeLeft]);

  useEffect(() => {
    if (gameState === "started" && startTime === null && socket) {
      initializeGame();
    }
  }, [gameState, startTime, socket, initializeGame]);

  const submitGame = useCallback(() => {
    if (isSubmitting) return;

    setIsSubmitting(true);
    if (socket) {
      const timeSpent = hasTimeLimit
        ? Math.min(
            Math.floor(gameData.timeLimit - (timeLeft ?? 0)),
            gameData.timeLimit
          )
        : getTimeSpent();
      socket.emit("submitChatGameResult", {
        miniGameId: gameData.id,
        timeSpent: timeSpent,
        chatHistory: chatHistory,
        isTimeUp: timeLeft === 0,
        userLocalDate: userLocalDate(),
      });
    }
  }, [
    isSubmitting,
    socket,
    hasTimeLimit,
    // startTime,
    gameData.id,
    gameData.timeLimit,
    chatHistory,
    timeLeft,
    getTimeSpent,
  ]);

  // const handleStartGame = () => {
  //   setGameState("started");
  //   setTimeLeft(gameData.timeLimit);
  //   setIsTyping(true);
  //   setIsInitialMessageComplete(false);
  //   setIsSubmitting(true);
  //   if (socket) {
  //     socket.emit("startChatGame", { miniGameId: gameData.id });
  //   }
  // };

  const handleSendMessage = () => {
    if (
      currentMessage.trim() &&
      socket &&
      !isTyping &&
      isInitialMessageComplete &&
      gameState === "started"
    ) {
      setIsTyping(true);
      const newMessage: ChatCompletionMessageParam = {
        role: "user",
        content: currentMessage.trim(),
      };
      setChatHistory((prev) => [...prev, newMessage]);
      socket.emit("sendChatMessage", {
        miniGameId: gameData.id,
        message: currentMessage.trim(),
        chatHistory: [...chatHistory, newMessage],
      });
      setCurrentMessage("");
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const getUserMessageStyle = () => ({
    alignSelf: "flex-end" as const,
    maxWidth: "80%",
    backgroundColor:
      colorScheme === "dark" ? theme.colors.blue[9] : theme.colors.blue[1],
    color: colorScheme === "dark" ? theme.colors.gray[0] : theme.colors.gray[9],
  });

  const getAssistantMessageStyle = () => ({
    alignSelf: "flex-start" as const,
    maxWidth: "80%",
    backgroundColor:
      colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[1],
    color: colorScheme === "dark" ? theme.colors.gray[0] : theme.colors.gray[9],
  });

  // if (gameState === "not_started") {
  //   return (
  //     <Button onClick={handleStartGame} mt="md">
  //       Commencer le jeu
  //     </Button>
  //   );
  // }

  return (
    <div>
      <LoadingOverlay
        visible={isSubmitting && gameState !== "started"}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1000,
        }}
      />
      <Paper
        withBorder={!isMobile}
        radius="xl"
        shadow="lg"
        style={{
          width: isMobile ? undefined : 450,
          position: isMobile ? undefined : "absolute",
          top: isMobile ? undefined : 16,
          left: isMobile ? undefined : 16,
        }}
        p={!isMobile ? "xs" : undefined}
        pt={0}
        mb={!isMobile ? "lg" : undefined}
      >
        {gameData.prompt.includes("[[INTERLOCUTOR]]") ||
        gameData.interlocutor ? (
          <ChatHeaderAvatar interlocutor={gameData.interlocutor} />
        ) : (
          <div style={{ height: 10 }} />
        )}
        <ScrollArea
          h={
            gameState === "submitted"
              ? 300
              : !isMobile
              ? "min(calc(100vh - 253px), 550px)"
              : 400
          }
          mb="md"
          viewportRef={scrollAreaRef}
        >
          <Stack ref={chatAreaRef}>
            {chatHistory.map((message, index) => (
              <ChatMessage
                key={index}
                message={message}
                getAssistantMessageStyle={getAssistantMessageStyle}
                getUserMessageStyle={getUserMessageStyle}
              />
            ))}
            {isTyping && (
              <Paper p="xs" withBorder style={getAssistantMessageStyle()}>
                <Loader type="dots" size="sm" />
              </Paper>
            )}
          </Stack>
        </ScrollArea>
        {["started", "finished"].includes(gameState) && (
          <Group justify="space-between" gap="xs" align="center">
            <Textarea
              placeholder="Entrez votre message ici"
              value={currentMessage}
              onChange={(event) => setCurrentMessage(event.currentTarget.value)}
              onKeyDown={handleKeyDown}
              mb="md"
              radius={isMobile ? undefined : "md"}
              disabled={gameState === "finished" || isSubmitting}
              style={{ flex: 1 }}
            />
            <ActionIcon
              onClick={handleSendMessage}
              disabled={
                isTyping ||
                !currentMessage.trim() ||
                !isInitialMessageComplete ||
                isSubmitting
              }
              mb="md"
            >
              <IconSend />
            </ActionIcon>
          </Group>
        )}
      </Paper>
      {gameState === "started" && !isSubmitting && (
        <div style={{ marginLeft }}>
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(gameData.description || ""),
            }}
          />
          <Button onClick={() => navigate(`/skills/${gameData.skillId}`)}>
            Retour
          </Button>
          {isInitialMessageComplete && (
            <Button onClick={() => setGameState("finished")} ml="md">
              Terminer le jeu
            </Button>
          )}
        </div>
      )}
      {(gameState === "finished" || gameState === "submitted") && (
        <GameResult score={score} feedback={feedback} />
      )}
    </div>
  );
};

export default ChatGame;
