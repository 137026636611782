import React from "react";
import { Box, Badge, Alert, Button, Loader, Text } from "@mantine/core";
import { IconFlame, IconAlertCircle } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import StreakCalendar from "./StreakCalendar";

interface StreakReminderProps {
  currentStreak: number;
  playedToday: boolean;
  results: any; // Remplacez 'any' par le type correct pour vos résultats
  isLoading: boolean;
  error: Error | null;
}

const StreakReminder: React.FC<StreakReminderProps> = ({
  currentStreak,
  playedToday,
  results,
  isLoading,
  error,
}) => {
  const navigate = useNavigate();
  const today = new Date().toLocaleString("fr-FR", { weekday: "long" });

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <Text c="red">Erreur lors du chargement des résultats</Text>;
  }

  return (
    <>
      <Box my={20}>
        <Badge size="xl" leftSection={<IconFlame size={18} />} variant="light">
          {currentStreak} jours de streak
        </Badge>
      </Box>

      {!playedToday && (
        <Alert
          icon={<IconAlertCircle size={16} />}
          title={currentStreak === 0 ? "Démarrez votre aventure !" : "Rappel"}
          color={currentStreak === 0 ? "green" : "blue"}
          mt={16}
          mb={16}
        >
          {currentStreak === 0 ? (
            <div>
              Commencez votre streak aujourd'hui ! Faites un mini-jeu ce {today}{" "}
              pour améliorer vos compétences.
            </div>
          ) : (
            <div>
              N'oubliez pas de faire votre mini-jeu du {today} pour maintenir
              votre streak !
            </div>
          )}
          <Button mt={15} onClick={() => navigate("/mini-games")}>
            Voir les Mini-Jeux
          </Button>
        </Alert>
      )}

      {results && <StreakCalendar results={results} />}
    </>
  );
};

export default StreakReminder;
