import { Group } from "@mantine/core";
import {
  IconBook,
  IconChartBar,
  IconDeviceGamepad,
  IconHome,
  IconTarget,
} from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";

const FooterMenuMobile = () => {
  const navigate = useNavigate();
  const margin = 9;

  return (
    <Group justify="space-around" style={{ height: "100%" }}>
      <IconHome onClick={() => navigate("/")} style={{ margin }} />
      <IconDeviceGamepad
        onClick={() => navigate("/mini-games")}
        style={{ margin }}
      />
      <IconTarget onClick={() => navigate("/quests")} style={{ margin }} />
      <IconChartBar
        onClick={() => navigate("/statistics")}
        style={{ margin }}
      />
      <IconBook onClick={() => navigate("/domains")} style={{ margin }} />
    </Group>
  );
};
export default FooterMenuMobile;
